<template>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem>
            <router-link :to="{ name: 'Templates' }"> Templates</router-link>
        </CBreadcrumbItem>
        <CBreadcrumbItem active>Task Template Settings</CBreadcrumbItem>
    </teleport>
    <teleport v-if="mountedComponent" to="#templates-header">
        <span class="template-page__heading" @click="fileData.loading = true">Task Template Settings</span>
    </teleport>

    <div class="template-page">
        <div v-if="mode" class="template-page__content">
            <div v-if="mode === modes.ONBOARDING">
                <OnboardingScreen/>
            </div>
            <div v-if="mode === modes.CREATE_CONTRACT">
                <div class="template-page__form">
                    <div class="template-page__form-partial">
                        <TemplateNameInput :model-value="contract.name" placeholder="Contract Template Name"
                                           @update:modelValue="(v) => (contract.name = v)"
                                           @on-validation="templateNameError = $event"/>

                        <OptionsDropdown :options="standardTemplatesOptions" :model-value="request.standardTemplateId"
                                         label="Use CC templates"/>

                        <FileUploadingArea :aria-disabled="templateNameError"
                                           :formats-title="`Supported only Word files (.${contract.availableFormats.join(', .')})`"
                                           :formats="contract.availableFormats"
                                           @fileLoaded="onFileSelected($event, 'contract')"/>

                    </div>
                    <div class="template-page__form-partial">
                        <div class="template-page__contract-info">
                            <img src="@/assets/images/requirements.png" alt=""/>
                            <span>File requirements</span>
                            <p>
                                To be able to add the merge fields, only .doc and .docx are supported at the time. Just
                                upload your <strong>contract</strong> Word document to create a template. Do not forget
                                to include the signature(s) merge fields in your template.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="mode === modes.CREATE_REQUEST || mode === modes.EDIT" :aria-disabled="!canEdit"
                 @click="formDirty = true">
                <div class="template-page__form">
                    <div class="template-page__form-partial">
                        <TemplateNameInput :model-value="request.name" class="template-page__name"
                                           placeholder="Task Template Name"
                                           @update:modelValue="(v) => (request.name = v)"
                                           @on-validation="templateNameError = $event"/>

                        <OptionsDropdown :aria-disabled="!request.name || templateNameError"
                                         :options="request.templateTypeActions" label="Task action"
                                         :model-value="request.selectedTemplateType"
                                         @update:modelValue="onTaskActionChange"/>

                        <DueDateSelector :settings="request.recurrence" :aria-disabled="!fieldsAvailable"
                                         file="TemplatePage" :permissions="[fieldsAvailable, 'recurrence']"
                                         @saved="request.recurrence = $event"/>

                        <textarea v-model="request.description" :aria-disabled="!fieldsAvailable" maxlength="1000"
                                  class="template-page__description" placeholder="Description">
                        </textarea>
                    </div>
                    <div :aria-disabled="!fieldsAvailable"
                         class="template-page__form-partial template-page__form-partial_spaced">
                        <OptionsDropdown :model-value="request.standardTemplateId" :options="standardTemplatesOptions"
                                         @update:modelValue="(value) => (request.standardTemplateId = value)"
                                         label="Use CC templates"/>

                        <div v-if="request.standardTemplateId" class="template-page__file">
                            <CIcon name="cilFile"/>
                            <p class="template-page__file-link"
                               @click="toggleDocumentPreviewer(true, 'standard-templates', request.standardTemplateId)">
                                Preview the document
                            </p>
                        </div>

                        <FileUploadingArea v-if="!request.standardTemplateId" :formats="request.availableFormats"
                                           :formats-title="`Supported file types: (${request.availableFormats.join(', ')})`"
                                           @fileLoaded="onFileSelected($event, 'request')"/>

                        <div v-if="fileData.file" class="template-page__file">
                            <CIcon name="cilFile"/>
                            <p v-if="mode !== 'edit'">{{ fileData.file.name }}</p>
                            <p v-else class="template-page__file-link" @click="onDownload">{{ fileData.file.name }}</p>
                            <CIcon style="color: red; cursor: pointer" name="cilTrash" @click="removeFileData"/>
                        </div>

                        <OptionsDropdown :model-value="request.selectedStatus" :options="request.statusesList"
                                         label="Status" @update:modelValue="(v) => (request.selectedStatus = v)"/>
                    </div>
                </div>
            </div>

            <div v-if="mode && mode !== modes.ONBOARDING" class="template-page__footer">
                <CButton color="primary" variant="outline" @click="onCancel">Cancel</CButton>
                <CButton v-if="canEdit" :disabled="!fieldsAvailable" color="primary" @click="onSave">Save</CButton>
            </div>
        </div>

        <div v-if="!mode" class="template-page__spinner">
            <CSpinner color="primary"/>
        </div>
        <CModal :visible="Boolean(modalData)" backdrop="static" @close="closeModal">
            <div class="template-page__modal template-page__modal-basic" :class="[modalData.className]">
                <CIcon v-if="modalData.icon" class="template-page__modal-icon" :name="modalData.icon"/>
                <div class="template-page__modal-info">
                    <h3 v-if="modalData.title" class="template-page__modal-title">{{ modalData.title }}</h3>
                    <p v-if="modalData.text" class="template-page__modal-text">{{ modalData.text }}</p>
                </div>

                <div v-if="modalData.name" class="template-page__modal-body">
                    <div v-if="modalData.name === 'info-acknowledgment'" class="form-check">
                        <input v-model="checkedActions.create_request_acknowledgement" class="form-check-input"
                               type="checkbox"/>
                        <label class="form-check-label" for="flexCheckDefault"> Do not show again </label>
                    </div>

                    <div v-if="modalData.name === 'info-request'" class="form-check">
                        <input v-model="checkedActions.create_request_document" class="form-check-input"
                               type="checkbox"/>
                        <label class="form-check-label" for="flexCheckDefault"> Do not show again </label>
                    </div>

                    <div v-if="modalData.name === 'change-type'" class="form-check">
                        <input v-model="checkedActions.update_request_to_acknowledgment" class="form-check-input"
                               type="checkbox"/>
                        <label class="form-check-label" for="flexCheckDefault"> Do not show again </label>
                    </div>
                </div>

                <div class="template-page__modal-buttons">
                    <div v-if="modalData.name === 'info-request'">
                        <CButton color="primary" @click="submitTypeInfoModal('create_request_document')">
                            Got it
                        </CButton>
                    </div>
                    <div v-if="modalData.name === 'info-acknowledgment'">
                        <CButton color="primary" @click="submitTypeInfoModal('create_request_acknowledgement')">
                            Got it
                        </CButton>
                    </div>
                    <div v-if="modalData.confirmButton">
                        <CButton color="primary" @click="closeModal">Got it</CButton>
                    </div>
                    <div v-if="modalData.name === 'file-duplicate'" class="template-page__modal-actions">
                        <CButton variant="outline" color="primary" @click="closeModal">Cancel</CButton>
                        <CButton color="primary">Continue</CButton>
                    </div>
                    <div v-if="modalData.name === 'change-type'" class="template-page__modal-actions">
                        <CButton variant="outline" color="primary" @click="onTypeChangeCancel">Cancel</CButton>
                        <CButton color="primary" @click="onTypeChangeConfirm">Continue</CButton>
                    </div>
                    <div v-if="modalData.name === 'status-draft'" class="template-page__modal-actions">
                        <CButton variant="outline" color="primary" @click="closeModal">Cancel</CButton>
                        <CButton color="primary" @click="onSave($event, true)">Save draft</CButton>
                    </div>
                </div>
            </div>
        </CModal>
        <CModal content-class-name="loading-modal" size="sm" :visible="fileData.loading" @close="stopLoading">
            <CModalBody>
                <div class="loading-modal__header">
                    <span class="loading-modal__title">
                        {{ fileData.file ? 'Uploading 1 item' : 'Loading...' }}
                    </span>
                </div>
                <div class="loading-modal__body">
                    <div v-if="fileData.file" class="loading-modal__body-file">
                        <i class="far fa-file"></i>
                        <span>{{ fileData.file.name }}</span>
                        ({{ Number(fileData.file.size / 1000).toFixed(2) }}kb)
                        <CSpinner style="flex-shrink: 0" color="primary" size="sm"></CSpinner>
                    </div>

                    <div v-else class="loading-modal__body-file loading-modal__body-file_loading">
                        <i class="far fa-file"></i>
                        <span>Saving changes, please wait</span>
                        <CSpinner style="flex-shrink: 0" color="primary" size="sm"></CSpinner>
                    </div>

                    <hr/>
                    <CButton style="margin: 0 auto; display: block" color="primary" variant="outline"
                             @click="stopLoading">
                        Cancel
                    </CButton>
                </div>
            </CModalBody>
        </CModal>
    </div>
    <DocumentPreviewer :visible="documentPreviewer.visible" content-type="origin" :type="documentPreviewer.type"
                       :type-id="documentPreviewer.typeId" :v2="documentPreviewer.v2"
                       @close="toggleDocumentPreviewer(false, null, null)"/>
</template>

<script>
import OnboardingScreen from '@/components/CreateTask/OnboardingScreen.vue';
import TemplateNameInput from '@/components/CreateTask/TemplateNameInput.vue';
import FileUploadingArea from '@/components/CreateTask/FileUploadingArea.vue';
import OptionsDropdown from '@/components/CreateTask/OptionsDropdown.vue';
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import AddTag from '@/components/CreateTask/AddTag.vue';
import Converter from '@/utils/templatesConverter/Converter';
import Gate from '@/services/Gate/Gate';
import ApiService from '@/services/ApiService';
import StandardTemplates from '@/api/v2/endpoints/StandardTemplates';
import Templates from "@/api/v2/endpoints/Templates";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";

const converter = new Converter();
const modes = {
    EDIT: 'edit',
    ONBOARDING: 'onboarding',
    CREATE_CONTRACT: 'create_contract',
    CREATE_REQUEST: 'create_request',
};
const actions = {
    ONBOARDING: 'create_template_page',
    CREATE_REQUEST: 'create_request_document',
    CREATE_ACKNOWLEDGEMENT: 'create_request_acknowledgement',
    Update_Request_To_Acknowledgment: 'update_request_to_acknowledgment',
};
const types = {
    CONTRACT: 'contract',
    REQUEST: 'request',
};
const modal_titles = {
    title1: 'File not supported!',
    title2: 'Oops, something went wrong.',
    title3: 'Requirements',
    title4: 'Requirements',
    title5: 'Duplicate or new version?',
    title6: 'Are you sure?',
    title7: 'Publish your template',
};
const modal_texts = {
    text1: 'Oops, this file format is not recognized yet by our platform. Here is the list of the supported formats: doc, docX',
    text2: 'Oops, this file size is not supported yet by our platform. Maximum file size - 100 mb.',
    text3: 'Please try again',
    text4: 'To request a document you have several options. You can upload a .doc or .docx files and add merge fields. Or you can upload other formats, but merge fields are not supported (at this time).',
    text5: 'To request an acknowledgment of receipt you have several options. You can upload a .doc or .docx files and add merge fields.  Or you can upload other formats, but merge fields is not supported (At the time)',
    text6: 'Oops, this file format is not recognized yet by our platform. Here is the list of the supported formats: doc, docx, xls, xlsx, pdf, jpg, png',
    text7: 'If you want to request acknowledgement of receipt, you have to upload a file',
    text8: 'It looks like the file has already been uploaded. Are you sure you want to upload this new one?',
    text9: 'It looks like you are changing the task action type. If you continue, we will redirect you to the appropriate screen.',
    text10: 'Are you sure you want to keep this template as a draft? You need to change the status to “Published” if you are ready to make the template available in the system.',
};
const vocabular = {
    Request: 'Request document',
    'Acknowledgement of Receipt Required': 'Request acknowledgment of receipt',
};

export default {
    name: 'TemplatePage',
    components: {
        AddTag,
        DueDateSelector,
        OptionsDropdown,
        FileUploadingArea,
        TemplateNameInput,
        OnboardingScreen,
        DocumentPreviewer
    },
    inject: ['toast', 'downloadToast'],
    data() {
        return {
            mode: null,
            actions: [],
            standardTemplates: [],
            checkedActions: {
                create_request_document: false,
                create_request_acknowledgement: false,
                update_request_to_acknowledgment: false,
            },
            mountedComponent: false,
            formDirty: false,
            canEdit: false,

            modalData: null,

            contract: {
                availableFormats: ['doc', 'docx'],
                name: '',
            },

            request: {
                availableFormats: ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg', 'gif', 'png'],
                selectedStatus: 'Draft',
                statusesList: ['Draft', 'Published'],
                selectedTemplateType: null,
                templateTypeActions: [
                    {
                        value: 'Request documents',
                        label: 'Request document'
                    },
                    {
                        value: 'Acknowledge',
                        label: 'Request acknowledgement of receipt'
                    }
                ],
                name: '',
                description: '',
                tags: [],
                tagsClearWatchCounter: 0,
                recurrence: null,
                standardTemplateId: null,
            },

            fileData: {
                element: null,
                file: null,
                loading: false,
                controller: null,
                file_changed: false,
            },

            savedSnapshot: null,
            templateNameError: false,

            documentPreviewer: {
                v2: false,
                type: null,
                typeId: null,
                visible: false,
            }
        };
    },
    computed: {
        modes() {
            return modes;
        },
        fieldsAvailable() {
            return !!(this.request.name && this.request.selectedTemplateType && !this.templateNameError);
        },
        redirectionFormat() {
            const formats = ['doc', 'docx'];
            const format = this.fileData.file?.name?.split('.').pop() || '';

            return formats.includes(format);
        },
        fileLoaded() {
            return this.fileData.file instanceof File;
        },
        standardTemplatesOptions() {
            if (this.request.selectedTemplateType === null) {
                return [];
            }

            return this.standardTemplates
                .filter(item => item.template_types.includes(this.request.selectedTemplateType))
                .map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
        },
    },
    watch: {
        '$route.query': function () {
            this.setPageMode();
        },
    },
    async created() {
        await this.loadStandardTemplates();
        await this.getActions();
        this.setPageMode();
        this.setEditPermission();

        if (this.mode === 'edit') {
            this.loadTemplateToEdit();
        }
    },
    mounted() {
        this.mountedComponent = true;
    },
    methods: {
        loadTemplateToEdit() {
            const params = {
                include_task_settings: 1,
                include_task_settings_recurrence: 1,
                include_requested_document: 1
            };

            Templates.show(this.$route.params.id, params)
                .then(response => this.fillForm(response.data));
        },
        async loadStandardTemplates() {
            await StandardTemplates.index().then(({data}) => (this.standardTemplates = data));
        },
        async getActions() {
            const actions = await this.$http.onboarding.getActions();
            this.actions = actions.data.data;
        },
        setEditPermission() {
            Gate.can('edit', 'template')
                .then(response => (this.canEdit = response));
        },
        getActionStatus(action) {
            const requestedAction = this.actions.find((el) => el.action === action);
            return requestedAction ? requestedAction.checked : false;
        },
        getPageMode() {
            if (this.$route.params.id) {
                return modes.EDIT;
            }

            if (this.$route.query.type) {
                const availableTypes = ['contract', 'request'];
                if (availableTypes.includes(this.$route.query.type)) {
                    return 'create_' + this.$route.query.type;
                }
            }

            if (!this.getActionStatus(actions.ONBOARDING)) {
                return modes.ONBOARDING;
            }
        },
        setPageMode() {
            this.mode = this.getPageMode();
        },

        onTypeChangeConfirm() {
            this.closeModal();

            if (this.checkedActions.update_request_to_acknowledgment) {
                this.$http.onboarding.changeAction({action: 'update_request_to_acknowledgment'}).then(() => {
                    const index = this.actions.findIndex((el) => el.action === 'update_request_to_acknowledgment');
                    this.actions[index].checked = true;
                    this.convertAndCreate();
                });
            } else {
                this.convertAndCreate();
            }
        },
        onTypeChangeCancel() {
            this.request.selectedAction = 'Request document';
            this.closeModal();
        },
        onTaskActionChange(action) {
            this.request.selectedTemplateType = action;
            this.request.standardTemplateId = null;
            Object.keys(this.checkedActions).forEach((v) => (this.checkedActions[v] = false));

            if (this.formDirty) {
                if (action === 'Request documents' && !this.getActionStatus(actions.CREATE_REQUEST)) {
                    return this.openModal('info-request');
                }

                if (action === 'Acknowledge' && !this.getActionStatus(actions.CREATE_ACKNOWLEDGEMENT)) {
                    return this.openModal('info-acknowledgment');
                }
            }
        },

        onFileSelected(e, mode) {
            if (this.fileData.file) {
                this.fileData.file_changed = true;
            }

            this.fileData.element = e.target;
            this.fileData.file = e.target.files[0];

            this.validateFile(this[mode].availableFormats)
                .then(() => {
                    if (this.redirectionFormat) {
                        this.convertAndCreate();
                    }
                })
                .catch((e) => {
                    this.removeFileData();
                    this.openModal('error-' + e);
                });
        },
        convertAndCreate() {
            this.fileData.loading = true;
            converter
                .convertFile(this.fileData.file)
                .then(response => {
                    if (this.fileData.loading) {
                        this.createTemplate(this.collectData(response.html), true);
                    }
                })
                .catch(() => {
                    if (this.fileData.loading) {
                        this.openModal('error-unexpected');
                    }

                    this.stopLoading();
                });
        },
        validateFile(formats) {
            return new Promise((resolve, reject) => {
                const fileFormat = this.fileData.file.name.split('.').pop(); //
                const fileSize = this.fileData.file.size;
                const maxSize = 1e8;

                if (!formats.includes(fileFormat)) {
                    reject('format');
                }

                if (fileSize > maxSize) {
                    reject('size');
                }

                resolve(true);
            });
        },
        removeFileData() {
            this.fileData.file = null;
            if (this.fileData.element) {
                this.fileData.element.value = '';
            }
        },
        collectData(payload) {
            const {packageId, priority} = this.$route.query;
            const filename = this.fileData.file?.name?.substring(0, this.fileData.file.name.lastIndexOf('.'));
            const data = new FormData();

            data.append('type', 'Contracts')
            data.append('name', this.contract.name || filename)
            data.append('content', payload || '')
            data.append('is_published', '0')

            if (packageId && priority) {
                data.append('folder_id', packageId)
                data.append('priority', priority)
            }

            if (this.mode === modes.CREATE_CONTRACT) {
                return data;
            }

            data.append('name', this.request.name || filename);
            data.append('type', this.request.selectedTemplateType);
            data.append('standard_template_id', this.request.standardTemplateId || '');
            data.append('description', this.request.description);
            data.append('is_published', this.request.selectedStatus === 'Published' ? '1' : '0');

            if (this.request.recurrence) {
                this.defineRecurrenceData(data);
            }

            if (this.fileData.file && !this.fileData.file?.id) {
                data.append('file', this.fileData.file);
            }

            if (this.mode === modes.EDIT) {
                data.append('_method', 'PUT');
            }

            return data;
        },
        defineRecurrenceData(data) {
            data.append('due_date_days', this.request.recurrence.due_date_days);
            data.append('recurrence_day_of_month', this.request.recurrence.recurrence_day_of_month);
            data.append('recurrence_enabled', this.request.recurrence.recurrence_enabled ? '1' : '0');
            data.append('recurrence_every', this.request.recurrence.recurrence_every);
            data.append('recurrence_period', this.request.recurrence.recurrence_period);
            data.append('recurrence_type', this.request.recurrence.recurrence_type);
            data.append('recurrence_until_date', this.request.recurrence.recurrence_until_date);
            data.append('recurrence_until_repeats', this.request.recurrence.recurrence_until_repeats);

            if (this.request.recurrence.recurrence_days_of_week?.length) {
                this.request.recurrence.recurrence_days_of_week.forEach((day) => {
                    data.append('recurrence_days_of_week[]', day);
                });
            }
        },
        toggleFileLoading() {
            this.fileData.loading = !this.fileData.loading;
        },
        cleanFileData() {
            this.fileData.loading = false;

            if (this.fileData.element) {
                this.removeFileData();
            }
        },
        openModal(name) {
            let data = null;

            function Modal(name, className, icon, title, text, confirmButton) {
                this.name = name;
                this.className = className;
                this.icon = icon;
                this.title = title;
                this.text = text;
                this.confirmButton = confirmButton;
            }

            switch (name) {
                case 'error-format':
                    data = new Modal(
                        name,
                        'modal_' + name,
                        'cil-face-dead',
                        modal_titles.title1,
                        this.mode === modes.CREATE_CONTRACT ? modal_texts.text1 : modal_texts.text6,
                        true,
                    );
                    break;
                case 'error-size':
                    data = new Modal(
                        name,
                        'modal_' + name,
                        'cil-face-dead',
                        modal_titles.title1,
                        modal_texts.text2,
                        true,
                    );
                    break;
                case 'error-unexpected':
                    data = new Modal(
                        name,
                        'modal_' + name,
                        'cil-face-dead',
                        modal_titles.title2,
                        modal_texts.text3,
                        true,
                    );
                    break;
                case 'info-request':
                    data = new Modal(name, 'modal_' + name, 'cil-file', modal_titles.title3, modal_texts.text4, false);
                    break;
                case 'info-acknowledgment':
                    data = new Modal(name, 'modal_' + name, 'cil-file', modal_titles.title3, modal_texts.text5, false);
                    break;
                case 'error-file':
                    data = new Modal(name, 'modal_' + name, 'cil-file', modal_titles.title4, modal_texts.text7, true);
                    break;
                case 'file-duplicate':
                    data = new Modal(name, 'modal_' + name, 'cil-file', modal_titles.title5, modal_texts.text8, false);
                    break;
                case 'change-type':
                    data = new Modal(
                        name,
                        'modal_' + name,
                        'cil-warning',
                        modal_titles.title6,
                        modal_texts.text9,
                        false,
                    );
                    break;
                case 'status-draft':
                    data = new Modal(name, 'modal_' + name, 'cil-file', modal_titles.title7, modal_texts.text10, false);
                    break;
            }

            if (data) {
                this.modalData = data;
            }
        },
        closeModal() {
            this.modalData = null;
        },
        submitTypeInfoModal(action) {
            if (this.checkedActions[action]) {
                this.$http.onboarding.changeAction({action}).then((res) => {
                    const index = this.actions.findIndex((el) => el.action === action);
                    if (this.actions[index]) {
                        this.actions[index].checked = true;
                    }
                    this.closeModal();
                });
            } else {
                this.closeModal();
            }
        },

        createTemplate(data, redirect) {
            if (this.mode === modes.CREATE_CONTRACT || this.mode === modes.CREATE_REQUEST) {
                this.toggleFileLoading();

                Templates.store(data)
                    .then(response => {
                        if (redirect) {
                            this.$router.push(`/templates/${response.data.id}/editor`)
                        } else {
                            this.$router.push(`/templates/${response.data.id}/request`);
                            this.toast('success', 'Template successfully created!');
                        }
                    })
                    .catch(() => this.openModal('error-unexpected'))
                    .finally(() => {
                        this.toggleFileLoading();
                        this.cleanFileData();
                    });

                return;
            }

            if (this.mode === modes.EDIT) {
                this.toggleFileLoading();

                Templates.update(this.$route.params.id, data)
                    .then(response => {
                        if (redirect) {
                            this.$router.push(`/templates/${response.data.id}/editor`)
                        } else {
                            this.toast('success', 'Template successfully updated!');
                            this.loadTemplateToEdit();
                        }
                    })
                    .catch(() => this.openModal('error-unexpected'))
                    .finally(() => {
                        this.toggleFileLoading();
                        this.cleanFileData();
                    });
            }
        },
        onSave(e, force) {
            if (this.modalData) {
                this.closeModal();
            }

            if (this.request.selectedAction === 'Request acknowledgment of receipt') {
                if (!this.fileData.file) {
                    return this.openModal('error-file');
                }
            }

            if (!force) {
                if (this.request.selectedStatus === 'Draft') {
                    return this.openModal('status-draft');
                }
            }

            this.createTemplate(this.collectData());
        },
        onCancel() {
            if (this.mode === modes.CREATE_CONTRACT) {
                this.$router.push('/templates');
                return;
            }

            if (!this.canEdit) {
                this.$router.push('/templates');
            }

            if (this.mode === modes.CREATE_REQUEST) {
                this.request.name = '';
                this.request.selectedAction = '';
                this.request.description = '';
                this.request.tags = [];
                this.request.selectedStatus = 'Draft';
                this.removeFileData();
                this.request.tagsClearWatchCounter++;
            }

            if (this.mode === modes.EDIT) {
                this.fillForm(this.savedSnapshot);
                this.request.tagsClearWatchCounter++;
            }
        },
        fillForm(data) {
            this.savedSnapshot = data;

            this.request.name = data.name;
            this.request.selectedTemplateType = data.task_settings.type;
            this.request.description = data.description;
            this.request.selectedStatus = data.is_published ? 'Published' : 'Draft';
            this.request.recurrence = {};
            this.request.recurrence.due_date_days = data.task_settings.due_date_days;
            this.request.recurrence.recurrence_day_of_month = data.task_settings_recurrence.day_of_month;
            this.request.recurrence.recurrence_days_of_week = data.task_settings_recurrence.days_of_week;
            this.request.recurrence.recurrence_enabled = data.task_settings_recurrence.enabled;
            this.request.recurrence.recurrence_every = data.task_settings_recurrence.every;
            this.request.recurrence.recurrence_period = data.task_settings_recurrence.period;
            this.request.recurrence.recurrence_type = data.task_settings_recurrence.type;
            this.request.recurrence.recurrence_until_date = data.task_settings_recurrence.until_date;
            this.request.recurrence.recurrence_until_repeats = data.task_settings_recurrence.until_repeats;
            this.fileData.file = data.requested_document;

            this.$nextTick(() => {
                this.request.standardTemplateId = data.standard_template_id;
            })
        },
        onDownload() {
            this.downloadToast.open(this.$route.params.id, this.fileData.file.name);
            this.downloadTemplate()
                .then((response) => {
                    this.downloadFile(response, this.fileData.file.name);
                    this.downloadToast.update(this.$route.params.id);
                })
                .catch(() => {
                    this.downloadToast.clear();
                    this.toast('danger', 'File download failed!');
                });
        },
        downloadTemplate() {
            return ApiService.api.common.downloadSingleDocument({
                type: 'request_file',
                template_id: this.$route.params.id,
                content_type: 'content',
            });
        },
        downloadFile(response, fileName, elId = undefined) {
            let blob = new Blob([response.data], {
                type: response.headers['content-type'],
            });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.target = '_blank';

            const el = elId ? document.getElementById(elId) : document.body;
            el.appendChild(link);
            link.click();
            el.removeChild(link);
        },
        toggleDocumentPreviewer(v2, type, typeId) {
            this.documentPreviewer.v2 = v2;
            this.documentPreviewer.type = type;
            this.documentPreviewer.typeId = typeId;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        }
    },
};
</script>

<style lang="scss">
.loading-modal {
    overflow: hidden;
    box-shadow: none !important;

    & .modal-body {
        padding: 0 !important;
        border-radius: 0.3em;
        overflow: hidden;
    }

    &__header {
        background: #202e39;
        padding: 16px 32px;
    }

    &__title {
        font-size: 16px;
        font-weight: 400;
        color: white;
    }

    &__body {
        padding: 16px 32px;
        max-width: 363px;

        &-file {
            display: flex;
            align-items: center;
            gap: 10px;

            &_loading {
                min-width: 210px;

                & .spinner-border {
                    margin-left: auto;
                }
            }

            & span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

.template-page {
    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }

    &__file {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        color: #000000;

        &-link {
            cursor: pointer;
            color: #0068ad;
            text-decoration: underline;
        }

        & svg {
            flex-shrink: 0;
        }

        & p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
        }
    }

    & .modal {
        &_info-acknowledgment,
        &_info-request {
            & svg {
                color: #0068ad;
            }
        }
    }

    &__description {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #9fafbc;
        color: #677a89;
        font-size: 16px;
        height: 112px;

        &:focus {
            outline: none;
        }
    }

    &__footer {
        margin-top: 110px;
        padding-top: 24px;
        border-top: 1px solid #c3cdd5;
        display: flex;
        gap: 15px;
    }

    &__contract-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
            width: 260px;
        }

        & span {
            font-size: 16px;
            margin-top: 50px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #1c262f;
        }

        & p {
            font-size: 14px;
            max-width: 400px;
            color: #677a89;
            text-align: center;
            font-weight: 400;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        &_request {
            & .template-page__name {
                max-width: 521px;
            }
        }

        &-partial {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 24px;

            max-width: 521px;

            &_spaced {
                padding-top: 95px;
            }
        }
    }

    &__content {
        background: white;
        border-radius: 8px;
        padding: 40px 24px;
    }

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        min-height: 70vh;

        background: #ffffff57;
        border-radius: 8px;
    }

    &__modal {
        &-basic {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 60px;
            width: 688px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            text-align: center;
            margin-bottom: 24px;
        }

        &-title {
            font-size: 24px;
            font-weight: 600;
            color: #1c262f;
        }

        &-text {
            font-size: 18px;
            color: #677a89;
        }

        &-icon {
            margin-bottom: 25px;

            height: 75px !important;
            width: 75px !important;
            color: #bac6cf;

            &_info {
                height: 48px !important;
                width: 48px !important;
                color: #0068ad;
            }

            &_warning {
                height: 80px !important;
                width: 80px !important;
                color: #bac6cf;
            }
        }

        &-cancellable {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;
        }

        &-actions {
            display: flex;
            gap: 16px;
        }

        &-buttons {
            display: flex;
            gap: 16px;
            margin-top: 32px;

            & button {
                min-width: 200px;
            }
        }
    }
}
</style>
