<template>
    <div class="onboarding-screen">
        <img class="onboarding-screen__image" src="@/assets/images/task-template.png" alt="Logo" />
        <h3 class="onboarding-screen__title">Need a contract or task template?</h3>
        <hr class="onboarding-screen__divider" />
        <div class="onboarding-screen__options">
            <div class="onboarding-screen__options-item">
                <div class="onboarding-screen__options-content">
                    <h5 class="onboarding-screen__options-title">Create contract template</h5>
                    <p class="onboarding-screen__options-text">
                        Just upload your <strong>contract</strong> document to create a template. Only files in .doc or
                        .docx formats allow the addition of the merge fields to your templates.
                    </p>
                    <br />
                    <p class="onboarding-screen__options-text">
                        Uploading a Word document is quite essential as the signature(s) merge fields are required for
                        templates to become contract templates.
                    </p>
                </div>
                <router-link :to="{ name: 'NewTemplateRequest', query: { type: 'contract', ...$route.query } }">
                    <CButton
                        color="primary"
                        variant="outline"
                        class="onboarding-screen__submit m-2 ml-0 d-block w-sm-100"
                        @click="onSubmit">
                        Create contract
                    </CButton>
                </router-link>
            </div>

            <div class="onboarding-screen__options-item">
                <div class="onboarding-screen__options-content">
                    <h5 class="onboarding-screen__options-title">Create task template</h5>
                    <p class="onboarding-screen__options-text">
                        For the tasks requiring <strong>requesting a document or acknowledgment</strong> you have
                        several options:
                    </p>
                    <ul class="onboarding-screen__options-list">
                        <li>Upload doc or .docx files and add merge fields</li>
                        <li>Upload other formats, but merge fields are not supported (at this time)</li>
                        <li>You can also create a task without a document attached</li>
                    </ul>
                </div>
                <router-link :to="{ name: 'NewTemplateRequest', query: { type: 'request', ...$route.query } }">
                    <CButton
                        color="primary"
                        variant="outline"
                        class="onboarding-screen__submit m-2 ml-0 d-block w-sm-100"
                        @click="onSubmit">
                        Create task
                    </CButton>
                </router-link>
            </div>
        </div>

        <div class="form-check onboarding-screen__checkbox">
            <input id="flexCheckDefault" v-model="checked" class="form-check-input" :value="true" type="checkbox" />
            <label class="form-check-label" for="flexCheckDefault">
                Do not show again. Directly access the Library
            </label>
        </div>

        <hr style="margin: 24px 0" />

        <router-link to="/templates">
            <CButton color="primary" @click="onSubmit">Access Library</CButton>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'OnboardingScreen',
    data() {
        return {
            checked: false,
        };
    },
    methods: {
        onSubmit() {
            if (this.checked) {
                this.$http.onboarding.changeAction({ action: 'create_template_page' });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.onboarding-screen {
    & a {
        text-decoration: none;
    }

    &__checkbox {
        margin-top: 59px;
    }

    &__title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin: 32px 0 24px 0;
    }

    &__image {
        height: 142px;
        display: block;
        margin: 0 auto;
    }

    &__divider {
        width: 88px;
        height: 2px;
        display: block;
        border: none;
        background: #0895d1;
        margin: 0 auto;
    }

    &__options {
        display: flex;
        margin-top: 32px;
        justify-content: center;

        &-content {
            min-height: 200px;
        }

        &-item {
            flex: 1;
            padding-right: 48px;
            max-width: 509px;
        }

        &-list {
            padding: 0 20px;
            margin: 0;

            & li {
                font-size: 14px;
                font-weight: 400;
                color: #677a89;
            }
        }

        &-title {
            font-size: 18px;
            color: #1c262f;
            font-weight: 600;
            margin-bottom: 15px;
        }

        &-text {
            font-size: 14px;
            font-weight: 400;
            color: #677a89;
            margin: 0;
        }

        & strong {
            font-weight: 700;
            font-size: 14px;
            color: #677a89;
        }
    }
}
</style>
