<template>
  <div class="template-name">
    <input
      class="template-name__input"
      :class="{'template-name__input_error': error || nameError}"
      maxlength="245"
      type="text"
      :value="modelValue"
      :placeholder="placeholder"
      @input="onInput" />

    <p v-if="error" style="font-size: 13px" class="text-error">Field is required</p>
    <p v-if="nameError" style="font-size: 13px" class="text-error mb-0">
      Task name can not contain the following symbols: {{ '<>:\"/\\|?*' }}
    </p>

    <p class="template-name__date">
      Created by
      {{ user.first_name }}
      {{ user.last_name }}
      {{ dateString }}
    </p>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

export default {
  props: ['modelValue', 'placeholder', 'error'],
  data() {
    return {
      nameError: false
    }
  },
  methods: {
    onInput(e) {
      const data = e.target.value;
      const regex = /[<>:"\/\\|?*]/;
      const isInvalid = regex.test(data);

      this.nameError = isInvalid;
      this.$emit('onValidation', isInvalid);
      this.$emit('update:modelValue',  e.target.value)
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.app.user,
    }),
    dateString() {
      return moment(new Date()).format('MM/DD/YYYY - HH:mm A')
    }
  },
}
</script>

<style scoped lang="scss">
.template-name {
  width: 100%;

  &__input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #C3CDD5;
    font-size: 28px;
    outline: none;

    &_error {
      border-bottom: 1px solid red !important;
    }

    &::placeholder {
      color: #C3CDD5;
    }
  }

  &__date {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 12px;
    color: #677A89;
  }
}
</style>
