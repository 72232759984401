<template>
  <div class="add-tag">

    <button class="add-tag__button" @click="modal = true">
      <CIcon style="color:#677A89;" name="cil-plus" />
      Add tag
    </button>

    <div v-if="savedLabels" class="add-tag__modal-labels">
      <div v-for="label in savedLabels" :key="label.id" class="add-tag__modal-label">
        <div class="add-tag__modal-labelx" @click.stop="removeLabelFromSaved(label.id)">
          <CIcon name="cil-x"></CIcon>
        </div>
        <p>{{ label.name }}</p>
      </div>
    </div>

    <CModal :visible="modal" @close="closeModal">
      <div class="add-tag__modal">
        <div class="add-tag__modal-heading">
          <CIcon name="cil-x" @click="closeModal" />
        </div>
        <span class="add-tag__modal-title">Add tags</span>
        <p class="add-tag__modal-text">
          Add existing tags or create new ones to facilitate the management of tasks,
          scopes and other items.
        </p>
        <hr class="add-tag__modal-divide" />

        <div class="add-tag__modal-dropdowns">

          <div class="tag-selector">
            <div class="tag-selector__input" @click="dd.existing = true">
              <CIcon name="cil-tag"></CIcon>
              <div class="tag-selector__placeholder">Existing Tags</div>
              <span
                class="tag-selector__chevron"
                :style="cssProps"
                aria-hidden="true"
              />
            </div>
            <div v-if="dd.existing" class="tag-selector__options" v-click-outside="() => onClickOutside('existing')">
              <div
                v-for="label in notSelectedLabels"
                :key="label.id"
                class="tag-selector__options-item"
                @click.stop="onTagSelect(label)">
                <p class="add-tag__text">{{ label.name }}</p>
              </div>

              <div v-if="notSelectedLabels && !notSelectedLabels.length" class="tag-selector__options-empty">
                No tags available
              </div>
            </div>
          </div>

          <div class="tag-selector">
            <div class="tag-selector__input" :class="{'tag-selector__input_error': errors}">
              <CIcon name="cil-tag"></CIcon>
              <input
                maxlength="100"
                :disabled="loading"
                v-model="newLabel"
                @keyup.enter="addNewLabel"
                @input="errors = null"
                placeholder="Add New Tags"
                type="text" />
            </div>
            <div v-if="errors">
              <div v-for="(errorsList, index) in errors" :key="'err'+index">
                <p
                  v-for="error in errorsList"
                  :key="error"
                  class="text-error"
                  style="font-size: 12px;margin-bottom: 0;margin-top: 2px;">
                  {{ error }}
                </p>
              </div>
            </div>
          </div>

        </div>

        <div v-if="selectedLabels && selectedLabels.length" class="add-tag__modal-labels add-tag__modal-labels_inner">
          <div v-for="label in selectedLabels" :key="label.id" class="add-tag__modal-label">
            <div class="add-tag__modal-labelx" @click.stop="removeLabel(label.id)">
              <CIcon name="cil-x"></CIcon>
            </div>
            <p>{{ label.name }}</p>
          </div>
        </div>

        <CButton class="add-tag__modal-save" color="primary" :disabled="loading" @click="save">
          Save
        </CButton>
      </div>
    </CModal>

  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "AddTag",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['clear-watch', 'tags'],
  data() {
    return {
      modal: false,
      cssProps: {
        '-webkit-mask-image': `url(${require('@/assets/Joel/caret.svg')})`,
        'background-color': '#0895D1',
        margin: 0,
      },
      dd: {
        existing: false
      },
      errors: null,

      labelsList: null,
      selectedLabels: [],
      newLabel: '',
      loading: false,
      savedLabels: false
    }
  },
  methods: {
    removeLabelFromSaved(id) {
      this.savedLabels = this.savedLabels.filter(label => label.id !== id)
      this.$emit('saved', this.savedLabels)
    },
    closeModal() {
      this.modal = false
      this.selectedLabels = []
    },
    save() {
      this.modal = false
      this.savedLabels = [...this.selectedLabels]
      this.$emit('saved', this.savedLabels)
    },
    addNewLabel() {
      if (this.newLabel) {
        this.loading = true
        this.$http.library.createLabel({ name: this.newLabel })
          .then(res => {
            this.labelsList = res.data.data
            this.selectedLabels.push(this.labelsList.find(label => label.name === this.newLabel))
            this.newLabel = ''
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
      }
    },
    onClickOutside(ddName) {
      this.dd[ddName] = false
    },
    onTagSelect(tag) {
      this.selectedLabels.push(tag)
      this.dd.existing = false
    },
    removeLabel(labelId) {
      this.selectedLabels = this.selectedLabels.filter(label => label.id !== labelId)
    },
  },
  watch: {
    modal(val) {
      if (val && this.savedLabels) {
        this.selectedLabels = [...this.savedLabels]
      }
    },
    clearWatch() {
      this.selectedLabels = []
      this.savedLabels = []
    },
    tags(val) {
      this.savedLabels = val
    }
  },
  computed: {
    notSelectedLabels() {
      if (this.selectedLabels.length) {
        return this.labelsList.filter(label => this.selectedLabels.every(el => el.id !== label.id))
      }

      return this.labelsList
    }
  },
  created() {
    this.$http.library.getLabels()
      .then(res => this.labelsList = res.data.data)
  }
}
</script>

<style lang="scss">
.add-tag {
  display: flex;
  align-items: center;
  gap: 15px;

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  &__button {
    color: #1C262F;
    font-size: 16px;
    background: none;
    border: none;
    text-decoration: underline;
    text-underline-offset: 4px;
    flex-shrink: 0;
  }


  &__modal {
    width: 560px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-save {
      width: 200px;
      margin-top: 48px;
    }

    &-labels {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;

      &_inner {
        margin-top: 22px;
      }
    }

    &-label {
      display: flex;
      align-items: center;
      gap: 3px;
      height: 21px;
      max-height: 21px;
      background: #0895D1;
      padding: 0 9px;
      border-radius: 200px;
      color: white;
      font-size: 10px;
      font-weight: 600;
      max-width: 100px;

      &x {
        height: 10px;
        width: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 200px;
        cursor: pointer;

        & svg {
          color: #0895D1;
          height: 8px !important;
        }
      }

      & p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
      }
    }

    &-heading {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & svg {
        height: 16px !important;
        width: 16px !important;
        color: #677A89;
        cursor: pointer;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 600;
      color: #1C262F;
    }

    &-text {
      font-size: 16px;
      color: #677A89;
      margin-top: 12px;
      margin-bottom: 12px;
      text-align: center;
    }

    &-divide {
      width: 120px;
      display: block;
      margin: 0 auto;
      height: 3px;
      background: #0081C2;
      border: none;
    }

    &-dropdowns {
      display: flex;
      width: 100%;
      margin-top: 32px;
      gap: 16px;
    }
  }

}

.tag-selector {
  flex: 1;
  cursor: pointer;
  position: relative;

  &__input {
    display: flex;
    align-items: center;
    border-radius: 8px;
    max-height: 56px;
    padding: 18px 16px;
    border: 1px solid #9FAFBC;

    &_error {
      border: 1px solid red;
    }

    & input {
      border: none;
      outline: none;
      margin-left: 4px;
      color: #677A89;

      &::placeholder {
        color: #BAC6CF;
      }
    }

    & svg {
      color: #0895D1;
      width: 25px;
    }
  }

  &__placeholder {
    flex: 2;
    margin-left: 4px;
    font-size: 16px;
    color: #677A89;
  }

  &__chevron {
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
  }

  &__options {
    padding: 16px 0;
    box-shadow: 0px 4px 12px 2px #0000001F;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    max-height: 280px;
    overflow: auto;

    &-empty {
      height: 36px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #677A89;
    }

    &-item {
      height: 36px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #677A89;

      &:hover {
        background: #677A89;
        color: white;
      }
    }
  }

}
</style>
